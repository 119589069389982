import * as React from "react"

import { graphql } from "gatsby"
import Header from "components/Header"
import Heading from "components/Heading"
import Footer from "components/Footer"
import Seo from "components/SEO";

import { 
    generalHeading,
    generalBody,
    generalBodyText,
    generalBodySection
} from './archive-blog.module.css'

const ArchiveBlog = ({ pageContext, data }) => {
    const { shopifyArticle } = data

    let generalContentJSX = (
        <div className={generalBodySection} >
            <div className={generalBodyText}>
                <div dangerouslySetInnerHTML={{__html: shopifyArticle.contentHtml}}></div>
            </div>
        </div>
    );

    return (
    <main>
        <Seo title={shopifyArticle.seo.title} description={shopifyArticle.seo.description} />
        <Header />

        <section>
            <div className={generalHeading}>
                <div>
                    <div>
                        <Heading level="h2">{shopifyArticle.title}</Heading>
                    </div>
                    <div>
                        <p>{""}</p>
                    </div>
                </div>
            </div>

            <div className={generalBody}>
                {generalContentJSX}
            </div>
        </section>
        <Footer />
    </main>
    )
}

export default ArchiveBlog

export const query = graphql`
    query SingleBlogQuery( $id: String ){
        shopifyArticle( id: {eq: $id} ) {
            seo {
                description
                title
            }
            contentHtml
            id
            title
            image {
                src
                altText
            }
        }
    }
    `